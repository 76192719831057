// extracted by mini-css-extract-plugin
export var dark = "_404-module--dark--fySId";
export var theme = "_404-module--theme--1aUF4";
export var headerWrapper = "_404-module--headerWrapper--rcfKP";
export var headerLink = "_404-module--headerLink--3xtvz";
export var sidebarToggleBtn = "_404-module--sidebarToggleBtn--3RPjC";
export var footer = "_404-module--footer--3rXJL";
export var sidebarContent = "_404-module--sidebarContent--5ldWm";
export var accordion__button = "_404-module--accordion__button--35B9h";
export var sidebarLink = "_404-module--sidebarLink--iBQbv";
export var heroButtons = "_404-module--heroButtons--2vU5W";
export var button = "_404-module--button--1dU8V";
export var hero = "_404-module--hero--11mMz";
export var post = "_404-module--post--eFY6m";
export var postNew = "_404-module--postNew--1Ctru";
export var searchInput = "_404-module--searchInput--29gcl";
export var socialLink = "_404-module--socialLink--19nSu";
export var tags = "_404-module--tags--1aagz";
export var tag = "_404-module--tag--1tdZP";
export var postTitle = "_404-module--postTitle--PDS8q";
export var blogPost = "_404-module--blogPost--2hSYT";
export var newBadge = "_404-module--newBadge--1e0Vl";
export var light = "_404-module--light--3R9oy";
export var desktopOnly = "_404-module--desktopOnly--2qMtx";
export var container = "_404-module--container--3yJ6W";
export var large = "_404-module--large--1zsvl";
export var small = "_404-module--small--2eWMI";
export var title = "_404-module--title--2zoUD";